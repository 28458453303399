import React, {useEffect, useState} from 'react';

// Table component
const CFAdminVehicleBundleMatrix = ({ matrixDataValues, matrixSubColumnValues, matrixColumnValues, handleSelectedValues, matrixSelectedValues}: any) => {

    const [selectedIds, setSelectedIds] = useState<any[]>([]);

    useEffect(() => {
        setSelectedIds(matrixSelectedValues);
    }, [matrixSelectedValues]);

    const handleMatrixClick = async (event: any, rowId: any) => {
        console.log('handleMatrixClick:', event.target.checked, 'rowId:', rowId);
        handleSelectedValues(rowId, event.target.checked);
        if (event.target.checked) {
            setSelectedIds((prevSelectedIds) => [...prevSelectedIds, rowId]);
        } else {
            setSelectedIds((prevSelectedIds) => prevSelectedIds.filter((id) => id !== rowId));
        }
        console.log('handleMatrixClick selectedIds:', selectedIds);
    };

    return (
        <div className="table-container">
            <table>
                <thead>
                <tr>
                    <th rowSpan={2} className="table-header"> &nbsp; </th>
                    <th rowSpan={2} className="table-header"> year </th>
                    {matrixColumnValues && matrixColumnValues.map((header: any, index: number) => (
                        <th key={'col-' + header.id} colSpan={header.colSpan}
                            className="table-header cell expand-maximum-on-hover">{header.name}</th>
                    ))}
                </tr>
                <tr>
                    {matrixSubColumnValues && matrixSubColumnValues.map((header: any, index: number) => (
                        <th key={'subcol-'+header.id} colSpan={header.colSpan} className="table-header cell expand-maximum-on-hover">{header.name}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {matrixDataValues && matrixDataValues.map((row: any) => (
                    <tr key={row.id}>
                        <td key={'subcol-' + row.id}>{row.name}</td>
                        <td key={'subcol-' + row.id}>{row.year}</td>
                        {row.subData && row.subData.map((subData: any) => (
                            <td key={'subcol-' + subData.id} className="center-items">
                                <div className="small-checkbox">
                                    <input type="checkbox" id={`checkbox-${subData.id}`}
                                           data-testid={`checkbox-${subData.id}`}
                                           checked={selectedIds.includes(subData.id)}
                                           onChange={(event) => handleMatrixClick(event, subData.id)}/>
                                    <label htmlFor={`checkbox-${subData.id}`}
                                           data-testid={`label-${subData.id}`}></label>
                                </div>
                            </td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default CFAdminVehicleBundleMatrix;