import React, {useCallback, useEffect, useRef, useState} from 'react';
import Stepper from "react-stepper-horizontal";
import CFAdminBundle from "./CFAdminBundle";
import BundleService from "../../services/BundleService";
import DrivingConditionService from "../../services/DrivingConditionService";
import {DrivingConditionLanguage} from "../CFAdminDrivingConds/types";
import {CountryLanguageEnum} from "../../constants/CountryLanguageEnum";
import IntervalService from "../../services/IntervalService";
import {AttributeType, AttributeValuesType, DualListBoxType} from "../CFAdminVehicleConfig/types";
import BundleAttributeDetails from "./BundleAttributeDetails";
import RecommendationService from "../../services/RecommendationService";
import BundleAttributeValueDetails from "./BundleAttributeValueDetails";
import CFAdminNotification from "../../components/CFAdminNotification";
import OperationService from "../../services/OperationService";
import {OperationLanguage} from "../CFAdminOperations/types";
import CFAdminBulkBundleConfirmation from "./CFAdminBulkBundleConfirmation";
import './CFAdminBulkBundle.scss'
import {AttributeIdEnum} from "../../constants/AttributeIdEnum";

export interface MultiSelectOption {
    readonly value: string;
    readonly label: string;
}

type CFAdminBulkBundleProps = {
    bundleIds?: any;
    operationIds?: any;
    intervalIds: any;
    drivingConditionIds: any;
    yearIds: any;
    makeIds: any;
    modelIds: any;
    attributeValueIds: any;
    updateOperations?: any;
};

function CFAdminBulkBundle({screen}: any) {

    const childCompRef = useRef<any>();
    const [activeStep, setActiveStep] = useState(0);
    const [nextStep, setNextStep] = useState(0);
    const [bundleOptions, setBundleOptions] = useState<MultiSelectOption[]>([]);
    const [stepperSteps, setStepperSteps] = useState<any>([]);
    const [drivingConditionOptions, setDrivingConditionOptions] = useState<MultiSelectOption[]>([]);
    const [intervalOptions, setIntervalOptions] = useState<MultiSelectOption[]>([]);
    const [attributeOptions, setAttributeOptions] = useState<DualListBoxType[]>([]);
    const [selectedAttributes, setSelectedAttributes ] = useState<string[]>([]);
    const [attributeValuesOptions, setAttributeValuesOptions] = useState<Map<string,DualListBoxType[]>>(new Map());
    const [selectedAttributeValues, setSelectedAttributeValues ] = useState<Map<string,string[]>>(new Map());
    const [bulkBundleConfigs, setBulkBundleConfigs] = useState<CFAdminBulkBundleProps>(
        {
            attributeValueIds: undefined,
            bundleIds: undefined,
            operationIds:undefined,
            drivingConditionIds: undefined,
            intervalIds: undefined,
            makeIds: undefined,
            modelIds: undefined,
            updateOperations: false,
            yearIds: undefined
        }
    );

    const [disableSaveandDelete, setDisableSaveandDelete] = useState(false);
    const [keysToBePresent] = useState<string[]>([]);
    const [resetKey, setResetKey] = useState(0);
    const getStepperSteps = () => {

        let steps = [];
        setActiveStep(0);
        setNextStep(0);
        setBulkBundleConfigs({
            attributeValueIds: undefined,
            bundleIds: undefined,
            operationIds:undefined,
            drivingConditionIds: undefined,
            intervalIds: undefined,
            makeIds: undefined,
            modelIds: undefined,
            updateOperations: undefined,
            yearIds: undefined
        });
        if(screen === 'bulkbundle'){
            steps.push({title: 'Bundles', onClick: () => setActiveStep(0)});
            getBundles();
        }
        if(screen === 'bulkoperation'){
            steps.push({title: 'Operations', onClick: () => setActiveStep(0)});
            getOperations();
        }

        steps.push({title: 'Driving Conditions', onClick: () => setActiveStep(1)});
        steps.push({title: 'Intervals', onClick: () => setActiveStep(2)});
        steps.push({title: 'Attributes', onClick: () => setActiveStep(3)});
        steps.push({title: 'Attribute Values', onClick: () => setActiveStep(4)});
        steps.push({title: 'Confirmation', onClick: () => setActiveStep(5)});
        setStepperSteps(steps);

        setSelectedAttributeValues(new Map());
        setSelectedAttributes([]);
        setDisableSaveandDelete(false);
        setResetKey(prevKey => prevKey + 1);
    };


    const handleStepperNext = (nextStep: number) => {
        setActiveStep(nextStep);
    };

    const getBundles = async () => {
        BundleService.getAll()
            .then(response => {
                const bundlesResponseModel = response.data?.bundlesResponseModel;
                if (bundlesResponseModel) {
                    let bundleOptions : MultiSelectOption[] = [];
                    bundlesResponseModel.forEach((element: any) => {
                        let data : MultiSelectOption = { label: element.bundleDescription, value:element.id};
                        bundleOptions.push(data);
                    });
                    setBundleOptions(bundleOptions);
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    const getOperations = async () => {
        OperationService.getAll()
            .then(response => {
                console.log('getOperations response:', response);
                const operationList = response.data?.operationList;
                if (operationList) {
                    let bundleOptions : MultiSelectOption[] = [];
                    operationList.forEach((element: any) => {
                        const operationLanguage: OperationLanguage | undefined = element.operationLanguageList.find((opLang: { countryLanguageId: CountryLanguageEnum; }) => opLang.countryLanguageId === CountryLanguageEnum.US_ENG);
                        let data : MultiSelectOption = { label: operationLanguage ? operationLanguage.description + '('+ element.operationCode + ')' : '',
                            value:element.operationId};
                        bundleOptions.push(data);
                    });
                    setBundleOptions(bundleOptions);
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    const getDrivingConditions = async () => {
        DrivingConditionService.getAll()
            .then(response => {

                const drivingConditionResponses = response.data?.drivingConditionResponses;
                if (drivingConditionResponses) {
                    let bundleOptions : MultiSelectOption[] = [];
                    drivingConditionResponses.forEach((element: any) => {
                        const drivingConditionLanguage: DrivingConditionLanguage | undefined = element.drivingConditionsLanguageList.find((dcLang: { countryLanguageId: CountryLanguageEnum; }) => dcLang.countryLanguageId === CountryLanguageEnum.US_ENG);
                        let data : MultiSelectOption = { label: drivingConditionLanguage ? drivingConditionLanguage.description : '',
                            value:element.drivingConditionId};
                        bundleOptions.push(data);
                    });
                    setDrivingConditionOptions(bundleOptions);
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    const getIntervals = async () => {
        IntervalService.getAll()
            .then(response => {

                const intervalResponses = response.data?.intervalResponses;
                if (intervalResponses) {
                    let bundleOptions : MultiSelectOption[] = [];
                    intervalResponses.forEach((element: any) => {
                        let data : MultiSelectOption = { label: element.intervalDesc,
                            value:element.intervalId};
                        bundleOptions.push(data);
                    });
                    setIntervalOptions(bundleOptions);
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    const getAttributes = async () => {
        RecommendationService.getAttributes()
            .then(response => {

                const responseModel = response.data?.attributes;
                if (responseModel) {
                    let attributes: DualListBoxType[] = [];
                    response.data?.attributes.forEach((element: AttributeType) => {
                        let option: DualListBoxType = {
                            value: String(element.attributeId),
                            label: element.attributeDescription!
                        };
                        attributes.push(option);
                    });
                    setAttributeOptions(attributes);
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    const processAttributeValues = (elements: any[], key: string, attributeValuesMap: Map<string, DualListBoxType[]>) => {
        let attributeValues: DualListBoxType[] = [];
        elements.forEach((element: any) => {
            let option: DualListBoxType = {
                value: String(element.id!),
                label: element.description!
            };
            attributeValues.push(option);
        });
        if (attributeValues.length > 0) {
            attributeValuesMap.set(key, attributeValues);
        }

        setAttributeValuesOptions(attributeValuesMap);
    };

    const processAttributeValuesWithId = (elements: AttributeValuesType[], attributeValuesMap: Map<string, DualListBoxType[]>, getAttributeLabel: (id: any) => string) => {
        elements.forEach((element: AttributeValuesType) => {
            let attributeKey: string = element.attributeId.toString();
            let option: DualListBoxType = {
                value: String(element.attributeValueId!),
                label: element.attributeValueDescription!
            };
            let attributeValues: DualListBoxType[] | undefined = attributeValuesMap.get(getAttributeLabel(attributeKey));
            if (attributeValues !== undefined) {
                attributeValues.push(option);
            } else {
                attributeValues = [option];
            }
            attributeValuesMap.set(getAttributeLabel(attributeKey), attributeValues);
        });
    };

    const getAttributeValues = async () => {
        let attributeIds: any = [];
        selectedAttributes.toString().split(/,\s*/).forEach((selValue: any) => {
            attributeIds.push(Number(selValue));
        });
        const request = {
            "attributeIds": attributeIds,
        };
        RecommendationService.getAttributeValues(request)
            .then(response => {
                const responseModel = response.data;
                if (responseModel) {
                    let attributeValuesMap: Map<string, DualListBoxType[]> = new Map();
                    processAttributeValues(response.data?.makes, 'Make', attributeValuesMap);
                    processAttributeValues(response.data?.models, 'Model', attributeValuesMap);
                    processAttributeValues(response.data?.years, 'Year', attributeValuesMap);
                    processAttributeValuesWithId(response.data?.attributeValues, attributeValuesMap, getAttributeLabel);

                }
            })
            .catch(e => {
                console.log(e);
            });
    };
    const getAttributeLabel = (id: any): string => {
        const found = attributeOptions.find((entry: DualListBoxType) => entry.value === id);
        if (found) {
            return found.label;
        }
        return '';
    }

    const getAllValuesFromSelectedAttributeValues = (): string[] => {
        let allValues: string[] = [];

        selectedAttributeValues.forEach((values,key) => {
            if (key !== 'Make' && key !== 'Model' && key !== 'Year') {
                (values.toString()).split(',').forEach(function(selValue: any) {
                    allValues.push(selValue);
                });
            }
        });
        return allValues;
    };

    const buildRequest = (screen: string): any => {
        const request: any = {
            intervalIds: bulkBundleConfigs.intervalIds?.map((interval: any) => interval.value),
            drivingConditionIds: bulkBundleConfigs.drivingConditionIds?.map((drivingCondition: any) => drivingCondition.value),
            yearIds: bulkBundleConfigs.yearIds,
            makeIds: bulkBundleConfigs.makeIds,
            modelIds: bulkBundleConfigs.modelIds,
            attributeValueIds: getAllValuesFromSelectedAttributeValues().map((value: string) => parseInt(value, 10)),
            updateOperations: bulkBundleConfigs.updateOperations
        };

        if (screen === 'bulkbundle') {
            request.bundleIds = bulkBundleConfigs.bundleIds?.map((bundle: any) => bundle.value);
        } else if (screen === 'bulkoperation') {
            request.operationIds = bulkBundleConfigs.operationIds?.map((operation: any) => operation.value);
        }

        return request;
    };

    const createBulkBundle = async () => {

        const request = buildRequest('bulkbundle');

        RecommendationService.createBulkBundle(request)
            .then(() => {
                setDisableSaveandDelete(true);
                confirmSuccessMessage("Vehicle Bundles created successfully!");
            })
            .catch(e => {
                setDisableSaveandDelete(true);
                console.error(e);
                confirmErrorMessage("Error in creating the Bundles in Bulk!");
            });
    };

    const createBulkOperation = async () => {

        const request = buildRequest('bulkoperation');

        RecommendationService.createBulkOperation(request)
            .then(() => {
                setDisableSaveandDelete(true);
                confirmSuccessMessage("Vehicle Operations created successfully!");
            })
            .catch(e => {
                setDisableSaveandDelete(true);
                console.error(e);
                confirmErrorMessage("Error in creating the Operations in Bulk!");
            });
    };

    const deleteBulkBundle = async () => {

        const request = buildRequest('bulkbundle');

        RecommendationService.deleteBulkBundle(request)
            .then(() => {
                setDisableSaveandDelete(true);
                confirmSuccessMessage("Vehicle Bundles deleted successfully!");
            })
            .catch(e => {
                setDisableSaveandDelete(true);
                console.error(e);
                confirmErrorMessage("Error in deleting the Bundles in Bulk!");
            });
    };

    const deleteBulkOperation = async () => {

        const request = buildRequest('bulkoperation');

        RecommendationService.deleteBulkOperation(request)
            .then(() => {
                setDisableSaveandDelete(true);
                confirmSuccessMessage("Vehicle Operations deleted successfully!");
            })
            .catch(e => {
                setDisableSaveandDelete(true);
                console.error(e);
                confirmErrorMessage("Error in deleting the Operations in Bulk!");
            });
    };

    useEffect(() => {
        getSectionComponent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStep, nextStep]);

    useEffect(() => {
        getStepperSteps();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screen]);


    function getSectionComponent() {
        if(activeStep === 0) {
            getDrivingConditions();
            getIntervals();
            getAttributes();
        }
        handleStepperNext(nextStep);
    }

    const resetForm = () => {
        setActiveStep(0);
        setNextStep(0);
        setBulkBundleConfigs({
            attributeValueIds: undefined,
            bundleIds: undefined,
            operationIds:undefined,
            drivingConditionIds: undefined,
            intervalIds: undefined,
            makeIds: undefined,
            modelIds: undefined,
            updateOperations: undefined,
            yearIds: undefined
        });

        setSelectedAttributeValues(new Map());
        setSelectedAttributes([]);
        setDisableSaveandDelete(false);
        setResetKey(prevKey => prevKey + 1);
    };

    const handlePreviousClick = () => {
        setNextStep(activeStep - 1);
    };

    const handleNextClick = () => {
        if (isBulkBundleStepValid()) {
            setNextStep(activeStep + 1);
        } else if (isBulkOperationStepValid()) {
            setNextStep(activeStep + 1);
        } else if (isDrivingConditionStepValid()) {
            setNextStep(activeStep + 1);
        } else if (isIntervalStepValid()) {
            setNextStep(activeStep + 1);
        } else if (isAttributeStepValid()) {
            setNextStep(activeStep + 1);
            getAttributeValues();
        } else if (isMakeAttributeInvalid()) {
            confirmErrorMessage("Please select at least one Makes Attribute");
        } else if (isModelAttributeInvalid()) {
            confirmErrorMessage("Please select at least one Models Attribute");
        } else if (isYearAttributeInvalid()) {
            confirmErrorMessage("Please select at least one Years Attribute");
        } else if (areAllAttributesSelected() && hasMakeIds(bulkBundleConfigs) && hasModelIds(bulkBundleConfigs) && hasYearIds(bulkBundleConfigs)) {
            setNextStep(activeStep + 1);
        } else {
            confirmErrorMessage("Please select values");
        }
    };

    const isBulkBundleStepValid = () => screen === 'bulkbundle' && activeStep === 0 && bulkBundleConfigs?.bundleIds?.length > 0;
    const isBulkOperationStepValid = () => screen === 'bulkoperation' && activeStep === 0 && bulkBundleConfigs?.operationIds?.length > 0;
    const isDrivingConditionStepValid = () => activeStep === 1 && bulkBundleConfigs?.drivingConditionIds?.length > 0;
    const isIntervalStepValid = () => activeStep === 2 && bulkBundleConfigs?.intervalIds?.length > 0;
    const isAttributeStepValid = () => activeStep === 3 && selectedAttributes?.length > 0;
    const isMakeAttributeInvalid = () => activeStep === 4 && !hasMakeIds(bulkBundleConfigs);
    const isModelAttributeInvalid = () => activeStep === 4 && !hasModelIds(bulkBundleConfigs);
    const isYearAttributeInvalid = () => activeStep === 4 && !hasYearIds(bulkBundleConfigs);

    function hasMakeIds(bulkBundleConfigs: { makeIds?: any[] }): boolean {
        if (bulkBundleConfigs.makeIds) {
            return bulkBundleConfigs.makeIds.length > 0;
        }

        return false;
    }

    function hasModelIds(bulkBundleConfigs: { modelIds?: any[] }): boolean {
        if (bulkBundleConfigs.modelIds) {
            return bulkBundleConfigs.modelIds.length > 0;
        }

        return false;
    }

    function hasYearIds(bulkBundleConfigs: { yearIds?: any[] }): boolean {
        if (bulkBundleConfigs.yearIds) {
            return bulkBundleConfigs.yearIds.length > 0;
        }

        return false
    }

    const areAllAttributesSelected = () => {
        for (let attributeId of selectedAttributes) {
            const values = selectedAttributeValues.get(getAttributeLabel(attributeId));
            if (!values || values.toString().trim().split(',').filter(Boolean).length === 0) {
                if(attributeId !== AttributeIdEnum.Make && attributeId !== AttributeIdEnum.Model && attributeId !== AttributeIdEnum.Year) {
                    return false;
                }
            }
        }

        return true;
    };

    const confirmErrorMessage = async (value: any) => {
        childCompRef.current.failureMessage(value);
    };

    const confirmSuccessMessage = async (value: any) => {
        childCompRef.current.successMessage(value);
    }

    const updateSelectedAttributeValues = useCallback(() => {
        // Remove keys from selectedAttributeValues that are not present in keysToBePresent
        selectedAttributeValues.forEach((value, key) => {
            if (!keysToBePresent.includes(key)) {
                selectedAttributeValues.delete(key);
            }
        });
    }, [keysToBePresent, selectedAttributeValues]);

    const handleSelectedAttributes = useCallback((values: any) => {
        const REQUIRED_ATTRIBUTES = ["1", "2", "14"];
        const updatedValues = Array.from(new Set([...REQUIRED_ATTRIBUTES, ...values]));
        setSelectedAttributes(updatedValues);
        keysToBePresent.length = 0;
        updatedValues.forEach((selValue: any) => {
            const foundOption = attributeOptions.find(option => option.value === String(selValue));
            if (foundOption) {
                keysToBePresent.push(foundOption.label);
            }
        });
        updateSelectedAttributeValues();
    }, [ attributeOptions, keysToBePresent, updateSelectedAttributeValues]);


    useEffect(() => {
        if (attributeOptions.length > 0) {
            handleSelectedAttributes([]);
        }
    }, [attributeOptions, handleSelectedAttributes]);





    const handleSelectedAttributeValues = (id: any, values: any) => {
        if (id === 'Make' || id === 'Model' || id === 'Year') {

            let attributeValues: any  = [];
            attributeValues.push(values.toString());
            selectedAttributeValues.set(id, attributeValues);
        }

        if(id === 'Make'){
            setBulkBundleConfigs((prevConfigs) => {
                return {
                    ...prevConfigs,
                    makeIds: values.map((value: string) => parseInt(value, 10)),
                };
            });
        }else if(id === 'Model'){

            setBulkBundleConfigs((prevConfigs) => {
                return {
                    ...prevConfigs,
                    modelIds: values.map((value: string) => parseInt(value, 10)),
                };
            });
        }else if(id === 'Year'){
            setBulkBundleConfigs((prevConfigs) => {
                return {
                    ...prevConfigs,
                    yearIds: values.map((value: string) => parseInt(value, 10)),
                };
            });
        }else{
            let attributeValues: any  = [];
            attributeValues.push(values.toString());
            selectedAttributeValues.set(id, attributeValues);

            let attributeValuesData: any  = [];
            values.forEach((attributeValueId: any) => {
                attributeValuesOptions.forEach((options) => {
                    options.forEach(option => {
                        if (option.value === attributeValueId) {
                            attributeValuesData.push(option.label);
                        }
                    });
                });
            });
        }
    }

    const handleBundleOptions = (id: any, values : any) => {

        if(id === 'bundle'){
            setBulkBundleConfigs({
                ...bulkBundleConfigs,
                bundleIds: values,
            });
        }else if(id === 'operation'){
            setBulkBundleConfigs({
                ...bulkBundleConfigs,
                operationIds: values,
            });
        }else if(id === 'drivingCondition'){
            setBulkBundleConfigs({
                ...bulkBundleConfigs,
                drivingConditionIds: values,
            });
        }else if(id === 'interval'){
            setBulkBundleConfigs({
                ...bulkBundleConfigs,
                intervalIds: values,
            });
        }

    }

    return (

        <div className="config-component">
            {screen === 'bulkbundle' && <h2 className="fomo-type--title">Recommend Bundles</h2>}
            {screen === 'bulkoperation' && <h2 className="fomo-type--title">Recommend Operations</h2>}
            <div>
                <div className="stepper">
                    <Stepper steps={stepperSteps} activeStep={activeStep}/>
                    <div className="active-step">
                        {activeStep !== 5 && <div className="container">
                            <div className="box">
                                <div className="box-row">
                                    <div className="box-cell box1">
                                        <div className="">
                                            <CFAdminBulkBundleConfirmation
                                                activeStep={activeStep}
                                                bulkBundleConfigs={bulkBundleConfigs}
                                                setBulkBundleConfigs={setBulkBundleConfigs}
                                                selectedAttributeValuesData={selectedAttributeValues}
                                                attributeValuesOptions={attributeValuesOptions}
                                                disableSaveAndDelete={disableSaveandDelete}
                                                selectedAttributesData={selectedAttributes}
                                                getAttributeLabel={getAttributeLabel}
                                            />
                                        </div>
                                    </div>
                                    <div className="box-cell box2">
                                        <div className="fvp-form__subgroup">
                                            {screen === 'bulkbundle' && activeStep === 0 &&
                                                <CFAdminBundle key={resetKey} id='bundle'
                                                               desc={'Please select the bundles you want to recommend.'}
                                                               bundleOptions={bundleOptions}
                                                               handleBundleOptions={handleBundleOptions}
                                                               activeStep={activeStep}
                                                               bulkBundleConfigs={bulkBundleConfigs}/>}
                                            {screen === 'bulkoperation' && activeStep === 0 &&
                                                <CFAdminBundle key={resetKey} id='operation'
                                                               desc={'Please select the Operation you want to recommend.'}
                                                               bundleOptions={bundleOptions}
                                                               handleBundleOptions={handleBundleOptions}
                                                               activeStep={activeStep}
                                                               bulkBundleConfigs={bulkBundleConfigs}
                                                />}
                                            {activeStep === 1 &&
                                                <CFAdminBundle id='drivingCondition'
                                                               desc={'Please select the Driving Conditions you want to recommend.'}
                                                               bundleOptions={drivingConditionOptions}
                                                               handleBundleOptions={handleBundleOptions}
                                                               activeStep={activeStep}
                                                               bulkBundleConfigs={bulkBundleConfigs}/>}
                                            {activeStep === 2 &&
                                                <CFAdminBundle id='interval'
                                                               desc={'Please select the Intervals you want to recommend.'}
                                                               bundleOptions={intervalOptions} handleBundleOptions={handleBundleOptions}
                                                               activeStep={activeStep}
                                                               bulkBundleConfigs={bulkBundleConfigs}/>}
                                            {activeStep === 3 &&
                                                <BundleAttributeDetails
                                                    attributeOptions={attributeOptions}
                                                    handleSelectedAttributes={handleSelectedAttributes}
                                                    activeStep={activeStep}
                                                    bulkBundleConfigs={bulkBundleConfigs}
                                                    selectedValues={selectedAttributes}
                                                />
                                            }
                                            {activeStep === 4 && <>
                                                <div className="ext1-1 center-align"><b>Please select the Attribute
                                                    Values you want to recommend.</b></div>
                                                <div className="attribute-value-container">
                                                    <div className="scroll">
                                                        <div>
                                                            {Array.from(attributeValuesOptions).map(([key, value]) => {
                                                                    // do stuff here
                                                                    return <BundleAttributeValueDetails
                                                                        id={key}
                                                                        key={key}
                                                                        data={value}
                                                                        selectedAttributeValues={selectedAttributeValues}
                                                                        handleSelectedAttributeValues={handleSelectedAttributeValues}
                                                                    />;
                                                                }
                                                            )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </>}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>}
                        {activeStep === 5 &&
                            <CFAdminBulkBundleConfirmation
                                activeStep={activeStep}
                                bulkBundleConfigs={bulkBundleConfigs}
                                setBulkBundleConfigs={setBulkBundleConfigs}
                                selectedAttributeValuesData={selectedAttributeValues}
                                attributeValuesOptions={attributeValuesOptions}
                                disableSaveAndDelete={disableSaveandDelete}
                            />
                        }
                    </div>

                    <div className="modal-footer button-frame">
                        <button data-testid="step-cancel" className="fvp-button--outline fvp-button"
                                type="submit"
                                onClick={resetForm}>
                            <i className="fvp-left-chevron"/>Cancel
                        </button>
                        <div className="right-align">
                            {(activeStep !== 0 && !disableSaveandDelete) && (
                                <button data-testid="step-prev"
                                        className="fvp-button button-right-align" type="button"
                                        onClick={handlePreviousClick}>
                                    <i className="fvp-left-chevron"/> Previous
                                </button>
                            )}
                            {activeStep !== stepperSteps.length - 1 && (
                                <button data-testid="step-next"
                                        className="fvp-button button-right-align" type="button"
                                        onClick={() => handleNextClick()}>
                                    Next <i className="fvp-right-chevron"/>
                                </button>
                            )}
                            {activeStep === stepperSteps.length - 1 && (
                                <div className="button-container">
                                    {!disableSaveandDelete && (
                                        <button data-testid="step-submit"
                                                className="fvp-button button-right-align" type="button"
                                                onClick={() => screen === 'bulkbundle' ? createBulkBundle() : createBulkOperation()}>
                                            Save <i className="fvp-right-chevron"/>
                                        </button>
                                    )}
                                    {!disableSaveandDelete && (
                                        <button data-testid="step-delete"
                                                className="fvp-button button-right-align red-button" type="button"
                                                onClick={() => screen === 'bulkbundle' ? deleteBulkBundle() : deleteBulkOperation()}>
                                            Delete <i className="fvp-right-chevron"/>
                                        </button>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <CFAdminNotification ref={childCompRef}/>
        </div>
    );
}

export default CFAdminBulkBundle;