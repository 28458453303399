import React, {useEffect, useState} from 'react';
import './CFAdminVehicleConfig.scss'
import Stepper from "react-stepper-horizontal";
import {Formik} from "formik";
import * as Yup from "yup";
import {
    AttributeType,
    AttributeValuesType,
    DualListBoxType,
    VehicleAttributeCombination, VehicleConfigType,
    VehicleDetailsProps
} from "./types";
import DualListBox from "react-dual-listbox";
import 'react-dual-listbox/lib/react-dual-listbox.css';
import VehicleConfigService from "../../services/VehicleConfigService";
import AttributeValueDetails from "./AttributeValueDetails";
import CFAdminVehicleConfigSelectDetails from "./CFAdminVehicleConfigSelectDetails";
import CFAdminVehicleConfigDetails from "./CFAdminVehicleConfigDetails";
import CFAdminConfirmDialog from "../../components/CFAdminConfirmDialog";
import CFAdminSystemSuggestedDetails from "./CFAdminSystemSuggestedDetails";

type CFAdminVehConfigProps = {
    screen: any;
    vehicleDetails: VehicleDetailsProps;
    closeModal: any;
    toggleModal: any;
    successMessage: any;
    errorMessage: any;
};

// Creating schema
const schema = Yup.object().shape({
    attributeIds: Yup.number().min(0),
});


function CFAdminVehicleConfigForm({screen,
                                      vehicleDetails,
                                      closeModal,
                                  toggleModal,
                                  successMessage,
                                  errorMessage,
                              }: CFAdminVehConfigProps) {
    const [activeStep, setActiveStep] = useState(0);
    const [nextStep, setNextStep] = useState(0);
    const [attributeOptions, setAttributeOptions] = useState<DualListBoxType[]>([]);
    const [selectedAttributes, setSelectedAttributes ] = useState([]);
    const [vinAttributes, setVinAttributes ] = useState([]);
    const [attributeValuesOptions, setAttributeValuesOptions] = useState<Map<string,DualListBoxType[]>>(new Map());
    const [selectedAttributeValues, setSelectedAttributeValues ] = useState<Map<string,string[]>>(new Map());
    const [vinAttributeValues, setVinAttributeValues ] = useState<Map<string,string[]>>(new Map());
    const [attrOptionUpdated, setAttrOptionUpdated ] = useState<boolean>(true);
    const [attrOptionValuesUpdated, setAttrOptionValuesUpdated ] = useState<boolean>(true);
    const [modalScreen, setModalScreen] = useState<string>(screen);
    const [modelYearId, setModelYearId] = useState<number>(0);
    const [attributeCombinations, setAttributeCombinations] = useState<VehicleAttributeCombination[]>([]);
    const [vehicleConfigs, setVehicleConfigs] = useState<VehicleConfigType[]>([]);
    const [isConfigConfirmOpen, setIsConfigConfirmOpen] = useState(false);
    const [isConfigAttributeNextButtonEligible, setConfigAttributeNextButtonEligible] = useState(false);
    const [isConfigAttributeValueNextButtonEligible, setConfigAttributeValueNextButtonEligible] = useState(false);
    const [isConfigVehicleConfirmButtonEligible, setConfigVehicleConfirmButtonEligible] = useState(false);

    const handleSubmit = (values: any) => {

        if(activeStep === 2) {
            setIsConfigConfirmOpen(true);
            setActiveStep(0);
        }
    };

    const steps = [
        {title: 'Choose Defining Attributes', onClick: () => setActiveStep(0)},
        {title: 'Select Attribute Values', onClick: () => setActiveStep(1)},
        {title: 'Select Valid Configurations', onClick: () => setActiveStep(2)},
    ];

    const handleStepperNext = (nextStep: number) => {
        setActiveStep(nextStep);
    };
    const handleNextClick = (values: any) => {

        selectedAttributeValues.forEach((value, key) => {
        });
        setNextStep(activeStep + 1);
    };

    const handlePreviousClick = () => {
        setNextStep(activeStep - 1);
    };

    const selectedAttributeCount = () =>{

        let selectedAttribute: any = [];
        (selectedAttributes.toString()).split(',').forEach(function(selValue: any) {
                if(selValue !== '')
                    selectedAttribute.push(selValue.toString());
        });

        if(selectedAttribute.length > 0){
            setConfigAttributeNextButtonEligible(true);
        } else{
            setConfigAttributeNextButtonEligible(false);
        }
    }

    const selectedAttributeValueCount = () =>{

        let selectedAttributes: any = [];

        selectedAttributeValues.forEach((value: any, key: any) => {

            (value.toString()).split(',').forEach(function(selValue: any) {
                if(selValue !== '')
                    selectedAttributes.push(selValue.toString());
            });
        });

        if(selectedAttributes.length > 0){
            setConfigAttributeValueNextButtonEligible(true);
        } else{
            setConfigAttributeValueNextButtonEligible(false);
        }
    }

    const selectedConfigurationCount = () =>{

        let selectedVehicleConfig: any = [];
        (vehicleConfigs.toString()).split(',').forEach(function(selValue: any) {
            if(selValue !== '')
                selectedVehicleConfig.push(selValue.toString());
        });

        if(selectedVehicleConfig.length > 0){
            setConfigVehicleConfirmButtonEligible(true);
        } else{
            setConfigVehicleConfirmButtonEligible(false);
        }
    }
    const enableNextButton = () => {

        if(activeStep === 0) {
                selectedAttributeCount();
                return isConfigAttributeNextButtonEligible;
        } else if(activeStep === 1) {
            selectedAttributeValueCount();
            return  isConfigAttributeValueNextButtonEligible;
        } else {
            selectedConfigurationCount();
            return  isConfigVehicleConfirmButtonEligible;
        }
    };

    const selectVinAttributes = () => {
        setSelectedAttributes(vinAttributes);
        setAttrOptionValuesUpdated(true);
        enableNextButton()
    };

    const switchToCreate = () => {
        setModalScreen("create");
    }

    const switchToEdit = () => {
        setModalScreen("edit");
    }

    useEffect(() => {
        if(modalScreen==='create' || screen ==='suggestedveh' || screen==='unsupportvehicle'){
            getSectionComponent();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStep, nextStep, modalScreen]);

    function getSectionComponent() {
        switch (activeStep) {
            case 0:
                getVehicleAttributes();
                if (nextStep === 1) {
                    handleStepperNext(1);
                }
                break;
            case 1:

                getVehicleAttributeValues();
                if (nextStep === 2) {
                    handleStepperNext(2);
                } else if (nextStep === 0) {
                    handleStepperNext(0);
                }
                break;
            case 2:
                getVehicleAttributeCombinations();
                if (nextStep === 1) {
                    handleStepperNext(1);
                }
                break;
            default:
                console.error("Unrecognized step");
        }
    }

    const processAttributes = (attributesData: AttributeType[]) => {
        let attributes: DualListBoxType[] = [];
        let selectedAttributes: any = [];
        let vinAttributes: any = [];

        attributesData.forEach((element: AttributeType) => {
            let option: DualListBoxType = {
                value: String(element.attributeId),
                label: element.inVin === 1 ? '*' + element.attributeDescription! : element.attributeDescription!
            };
            attributes.push(option);
            if (element.inQCM === 1) {
                selectedAttributes.push(String(element.attributeId));
            }
            if (element.inVin === 1) {
                vinAttributes.push(String(element.attributeId));
            }
        });

        return { attributes, selectedAttributes, vinAttributes };
    };

    const getVehicleAttributes = async () => {
        if (attrOptionUpdated) {
            let data = {
                year: vehicleDetails?.year,
                make: vehicleDetails?.make,
                existingModelDesc: vehicleDetails?.model,
                newModelDesc: vehicleDetails?.modelDesc
            };

            try {
                const response = await VehicleConfigService.getVehicleAttributes(data);
                const responseModel = response.data?.attributes;
                if (responseModel) {
                    const { attributes, selectedAttributes, vinAttributes } = processAttributes(responseModel);
                    setModelYearId(response.data?.modelYearId);
                    setAttributeOptions(attributes);
                    setSelectedAttributes(selectedAttributes);
                    setVinAttributes(vinAttributes);
                    setAttrOptionUpdated(false);
                }
            } catch (e) {
                console.log(e);
            }
        }
    };

    const createVehicleConfigurations = async () => {

        let data = {
            "vehicles": vehicleConfigs,
            "modelYearId":modelYearId
        };
        VehicleConfigService.createVehicleConfigurations(data)
            .then(response => {

                setIsConfigConfirmOpen(false);
                if(screen ==='suggestedveh' || screen==='unsupportvehicle'){
                    closeModal();
                }else{
                    setModalScreen("edit");
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    const getVehicleAttributeCombinations = async () => {

        let attributeIds: any = [];
        let attributeValues: any = [];
        selectedAttributeValues.forEach((value, key) => {
            attributeIds.push(Number(key));
            (value.toString()).split(',').forEach(function(selValue: any) {
                attributeValues.push(Number(selValue));
            });
        });
        let data = {
            "attributeIds": attributeIds,
            "attributeValueIds": attributeValues,
            "modelYearId":modelYearId
        };
        VehicleConfigService.getVehicleAttributeCombinations(data)
            .then(response => {
                setAttributeCombinations(response.data.vehicles);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const processAttributeValuesMapSelected = (element: AttributeValuesType, attributeValuesMapSelected: Map<string, string[]>) => {
        let attributeKey: string = element.attributeId.toString();
        if (element.inQCM === 1) {
            let attributeValuesSelected: string[] | undefined = attributeValuesMapSelected.get(attributeKey);
            if (attributeValuesSelected !== undefined) {
                attributeValuesSelected.push(String(element.attributeValueId));
            } else {
                attributeValuesSelected = [String(element.attributeValueId!)];
            }
            attributeValuesMapSelected.set(attributeKey, attributeValuesSelected);
        }
    };

    const processAttributeValuesMapVin = (element: AttributeValuesType, attributeValuesMapVin: Map<string, string[]>) => {
        let attributeKey: string = element.attributeId.toString();
        if (element.inVin === 1) {
            let attributeValuesVin: string[] | undefined = attributeValuesMapVin.get(attributeKey);
            if (attributeValuesVin !== undefined) {
                attributeValuesVin.push(String(element.attributeValueId));
            } else {
                attributeValuesVin = [String(element.attributeValueId!)];
            }
            attributeValuesMapVin.set(attributeKey, attributeValuesVin);
        }
    };

    const processAttributeValuesMap = (element: AttributeValuesType, attributeValuesMap: Map<string, DualListBoxType[]>) => {
        let attributeKey: string = element.attributeId.toString();
        let option: DualListBoxType = {
            value: String(element.attributeValueId!),
            label: element.inVin === 1 ? '*' + element.attributeValueDescription! : element.attributeValueDescription!
        };
        let attributeValues: DualListBoxType[] | undefined = attributeValuesMap.get(attributeKey);
        if (attributeValues !== undefined) {
            attributeValues.push(option);
        } else {
            attributeValues = [option];
        }
        attributeValuesMap.set(attributeKey, attributeValues);
    };

    const getVehicleAttributeValues = async () => {
        if (attrOptionValuesUpdated) {
            let selectedAttributesRequest: any = [];
            selectedAttributes.toString().split(/,\s*/).forEach((selValue: any) => {
                selectedAttributesRequest.push(Number(selValue));
            });
            let data = {
                "modelYearId": modelYearId,
                "attributeIds": selectedAttributesRequest
            };
            try {
                const response = await VehicleConfigService.getVehicleAttributeValues(data);
                const responseModel = response.data?.attributeValues;
                if (responseModel) {
                    let attributeValuesMap: Map<string, DualListBoxType[]> = new Map();
                    let attributeValuesMapSelected: Map<string, string[]> = new Map();
                    let attributeValuesMapVin: Map<string, string[]> = new Map();
                    response.data?.attributeValues.forEach((element: AttributeValuesType) => {
                        processAttributeValuesMapSelected(element, attributeValuesMapSelected);
                        processAttributeValuesMapVin(element, attributeValuesMapVin);
                        processAttributeValuesMap(element, attributeValuesMap);
                    });
                    setAttributeValuesOptions(attributeValuesMap);
                    setSelectedAttributeValues(attributeValuesMapSelected);
                    setVinAttributeValues(attributeValuesMapVin);
                }
                setAttrOptionValuesUpdated(false);
            } catch (e) {
                console.log(e);
            }
        }
    };

    const handleSelectedAttributeValues = (id: any, values: any) => {

        let attributeValues: any  = [];
        attributeValues.push(values.toString());
        selectedAttributeValues.set(id, attributeValues);
    }

    const handleSelectedConfigValues = (selectedRows: any) => {

        let vehicleConfigs: VehicleConfigType[]  = [];
        selectedRows.map((row : any ,index : any)=>{

            let vehicleConfig: VehicleConfigType = {
                vehicleId: Number(row.original.vehicleId!),
                attributeValueIds: row.original.attributeValueIds!
            };
            vehicleConfigs.push(vehicleConfig);
            return vehicleConfigs;
        });
        setVehicleConfigs(vehicleConfigs);
    };

    return (
        <>
        <Formik
            validationSchema={schema}
            initialValues={{ vehicleDetails: vehicleDetails  }}
            onSubmit={handleSubmit}
        >
            {({values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, dirty}) => (
                <div className="fvp-form">
                    <div className="modal-top-div">
                        <form noValidate onSubmit={handleSubmit}>
                            <div className="modal-body">
                                { (modalScreen === 'create' || screen ==='suggestedveh' || screen==='unsupportvehicle') &&
                                <div className="stepper">
                                    <Stepper steps={steps} activeStep={activeStep}/>
                                    <div className="active-step">
                                        <>
                                            {activeStep === 0 &&
                                            <>
                                                <div className="ext1">
                                                    Please choose all vehicle attributes that will be specified in
                                                    the <b>{vehicleDetails.year + ' ' + vehicleDetails.make + ' ' + vehicleDetails.model}</b> vehicle configurations.
                                                </div>
                                                <div className="ext2">
                                                    Use the arrow buttons to move the defining vehicle attributes to the
                                                    list on the right.
                                                </div>
                                                <div className="ext3">
                                                    <i>* Values represented by VIN data</i>
                                                </div>
                                                <div className="ext4">
                                                    <DualListBox
                                                        options={attributeOptions}
                                                        selected={selectedAttributes}
                                                        onChange={(newValue: any) => {
                                                            setSelectedAttributes(newValue);
                                                            setAttrOptionValuesUpdated(true);
                                                            enableNextButton()
                                                        }}
                                                    />
                                                    <div className="fvp-select-vin-div">
                                                        <button data-testid="select-vin"
                                                                className="fvp-select-vin-button" type="button"
                                                                disabled={vinAttributes.length === 0}
                                                                onClick={() => selectVinAttributes()}>
                                                            Select VIN <i className="fvp-right-chevron"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                            }
                                            {activeStep === 1 && (
                                                <>
                                                    <div className="ext1">
                                                        Please select all possible values for the <b>{vehicleDetails.year + ' ' + vehicleDetails.make + ' ' + vehicleDetails.model}</b> vehicle attributes
                                                        listed below.
                                                    </div>
                                                    <div className="ext2">
                                                        Use the arrow buttons to move the defining vehicle attributes to
                                                        the list on the right.
                                                    </div>
                                                    <div className="ext3">
                                                        <i>* Values represented by VIN data</i>
                                                    </div>
                                                    <div className="scroll">
                                                        {
                                                            Array.from(attributeValuesOptions).map(([key, value]) => {
                                                                // do stuff here
                                                                return <AttributeValueDetails
                                                                    key={key}
                                                                    id={key}
                                                                    data={value}
                                                                    selectedAttributeValues={selectedAttributeValues}
                                                                    handleSelectedAttributeValues={handleSelectedAttributeValues}
                                                                    vinAttributeValues={vinAttributeValues}
                                                                    enableNextButton={enableNextButton}
                                                                />;
                                                            }

                                                            )
                                                        }
                                                    </div>
                                                </>
                                            )}
                                            {activeStep === 2 && (
                                                <>
                                                    <CFAdminVehicleConfigSelectDetails
                                                        attributeCombinations={attributeCombinations}
                                                        handleSelectedConfigValues={handleSelectedConfigValues}
                                                        vehicleDetails={vehicleDetails}
                                                        successMessage={successMessage}
                                                        errorMessage={errorMessage}
                                                    />
                                                </>
                                            )}
                                        </>
                                    </div>
                                    <div className="modal-footer button-frame">
                                        <button data-testid="step-cancel" className="fvp-button--outline fvp-button"
                                                type="submit"
                                                onClick={toggleModal}>
                                            <i className="fvp-left-chevron"/>Cancel
                                        </button>
                                        <div className="right-align">
                                            {(activeStep !== 0) && (
                                                <button data-testid="step-prev"
                                                        className="fvp-button button-right-align" type="button"
                                                        onClick={handlePreviousClick}>
                                                    <i className="fvp-left-chevron"/> Previous
                                                </button>
                                            )}
                                            {activeStep !== steps.length - 1 && (
                                                <button data-testid="step-next"
                                                        className="fvp-button button-right-align" type="button"
                                                        disabled={!enableNextButton()}
                                                        onClick={() => handleNextClick(values)}>
                                                    Next <i className="fvp-right-chevron"/>
                                                </button>
                                            )}
                                            {activeStep === steps.length - 1 && (
                                                <button data-testid="step-submit"
                                                        className="fvp-button button-right-align" type="submit"
                                                        disabled={!enableNextButton()}>
                                                    Confirm <i className="fvp-right-chevron"/>
                                                </button>
                                            )}

                                        </div>
                                    </div>
                                </div>
                                }
                                {modalScreen === 'edit' &&
                                    <>
                                        <CFAdminVehicleConfigDetails
                                            vehicleDetails={vehicleDetails}
                                            switchToCreate = {switchToCreate}
                                            closeModal={closeModal}
                                            successMessage={successMessage}
                                            errorMessage={errorMessage}
                                        />
                                    </>
                                }
                                {modalScreen === 'suggestedconfig' &&
                                    <>
                                        <CFAdminSystemSuggestedDetails
                                            vehicleDetails={vehicleDetails}
                                            switchToEdit = {switchToEdit}
                                            closeModal={closeModal}
                                            successMessage={successMessage}
                                            errorMessage={errorMessage}
                                        />
                                    </>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </Formik>
            <CFAdminConfirmDialog
                isOpen={isConfigConfirmOpen}
                onClose={()=>setIsConfigConfirmOpen(false)}
                onConfirm={createVehicleConfigurations}
                title={`Create Configurations : ${vehicleDetails.make} ${vehicleDetails.model} and Year: ${vehicleDetails.year}`}
                text="Are you sure you want to Confirm this Configurations?"
            />
        </>
    );
}

export default CFAdminVehicleConfigForm;