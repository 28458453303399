import React, {useCallback, useEffect, useRef, useState} from 'react';
import Stepper from "react-stepper-horizontal";
import './CFAdminVehicleBundle.scss'
import CFAdminNotification from "../../components/CFAdminNotification";
import VehicleConfigService from "../../services/VehicleConfigService";
import {FVPSelect} from "../../components/formField";
import {Formik} from "formik";
import * as Yup from "yup";
import Select from "react-select";
import {MultiSelectOption} from "../CFAdminBulkBundle";
import OperationService from "../../services/OperationService";
import {OperationLanguage} from "../CFAdminOperations/types";
import {CountryLanguageEnum} from "../../constants/CountryLanguageEnum";
import BundleService from "../../services/BundleService";
import RecommendationService from "../../services/RecommendationService";
import CFAdminDataTable from "../../components/CFAdminDataTable";
import {CellValueAnchorButton, CheckboxButton, CellValueOperationAnchorButton} from "../../components/CFAdminDataTable/ActionsButtonComponents";
import CFAdminVehicleBundleMatrix from "./CFAdminVehicleBundleMatrix";
import CFAdminModal from "../../components/CFAdminModal";
import DrivingConditionService from "../../services/DrivingConditionService";
import IntervalService from "../../services/IntervalService";
import {DrivingConditionLanguage} from "../CFAdminDrivingConds/types";


type OptionType = { description: string; code?: string };
type CFAdminVehicleBundleProps = {
    bundleId: any;
    modelId: any;
    yearId: any;
};
type ModelYear = {
    yearId: number;
    year: string;
    inQCM: boolean;
    modelYearId?: number;
};


const schema = Yup.object().shape({
    year: Yup.string()
        .required("Year is a required field"),
});

const BundleCell = (value: any, bundleId: any, props: any, handleChange: Function) => {
    return (
        <div>
            {renderIdButton(value, bundleId, props, handleChange)}
        </div>
    );
};

const renderIdButton = (value: any, bundleId: any, props: any, handleChange: Function) => {
    return <CellValueAnchorButton value={value} bundleId={bundleId} {...props} handleChange={handleChange}/>
}

const VehicleOperationModelYearCell = (value: boolean, props: any, handleChange: Function) => {
    return <CheckboxButton className='operation-checkbox' checked={value} {...props} handleChange={handleChange}/>
}

const VehicleOperationAnchorCell = (value: boolean, bundleId: any, handleChange: Function) => {
    return <CellValueOperationAnchorButton value={value} bundleId={bundleId} handleChange={handleChange} />
}

type SelectedIdType = {
    id: any;
    value: boolean;
};

function CFAdminVehicleBundle({screen}: any) {

    const childCompRef = useRef<any>();
    const [activeStep, setActiveStep] = useState(0);
    const [nextStep, setNextStep] = useState(0);
    const [stepperSteps, setStepperSteps] = useState<any>([]);
    const [yearsOptions, setYearsOptions] = useState<OptionType[]>([]);
    const [modelOptions, setModelOptions] = useState<OptionType[]>([]);
    const [operationOptions, setOperationOptions] = useState<MultiSelectOption[]>([]);
    const [selectedOperationOptions, setSelectedOperationOptions] = useState<any[]>([]);
    const [drivingConditionOptions, setDrivingConditionOptions] = useState<MultiSelectOption[]>([]);
    const [selectedDrivingConditionOptions, setSelectedDrivingConditionOptions] = useState<any[]>([]);
    const [intervalOptions, setIntervalOptions] = useState<MultiSelectOption[]>([]);
    const [selectedIntervalOptions, setSelectedIntervalOptions] = useState<any[]>([]);
    const [vehicleOperationModelYears, setVehicleOperationModelYears] = useState<ModelYear[]>([]);
    const [bundleOptions, setBundleOptions] = useState<MultiSelectOption[]>([]);
    const [selectedBundleOptions, setSelectedBundleOptions] = useState<any[]>([]);
    const [columnValues, setColumnValues] = useState<any>([]);
    const [dataValues, setDataValues] = useState<any>([]);
    const [matrixDataValues, setMatrixDataValues] = useState<any[]>([]);
    const [matrixSelectedValues, setMatrixSelectedValues] = useState<any[]>([]);
    const [matrixColumnValues, setMatrixColumnValues] = useState<any[]>([]);
    const [matrixSubColumnValues, setMatrixSubColumnValues] = useState<any[]>([]);
    const [selectedIds, setSelectedIds] = useState<SelectedIdType[]>([]);
    const [updateOperations, setUpdateOperations] = useState(true);
    const [isYearSelection, setIsYearSelection] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [createdRecords , setCreatedRecords] = useState<any>('');
    const [deletedRecords, setDeletedRecords] = useState<any>('');
    const [isSaveButtonClicked, setIsSaveButtonClicked] = useState(false);
    const [vehicleTextDetails, setVehicleTextDetails] = useState<any>('');
    const [selectedYear, setSelectedYear] = useState<any>(0);

    const [vehicleDetails, setVehicleDetails] = useState<CFAdminVehicleBundleProps>({
        yearId: '',
        modelId: '',
        bundleId: '',
    });

    const toggleModal = () => {
        setIsOpen(!isOpen);
    }

    const getStepperSteps = () => {

        let steps = [];
        setActiveStep(0);
        setNextStep(0);

        if(screen==='vehiclebundle'){
            steps.push({title: 'Year and Bundles', onClick: () => setActiveStep(1)});
            steps.push({title: 'Existing Vehicles', onClick: () => setActiveStep(2)});
        }else{
            steps.push({title: 'Model and Operation', onClick: () => setActiveStep(1)});
            steps.push({title: 'Existing Years', onClick: () => setActiveStep(2)});
        }

        steps.push({title: 'Confirm Recommendations', onClick: () => setActiveStep(3)});
        setStepperSteps(steps);

    };

    const handleSelectedValues = (id: any, isSelected: any) => {
        const newSelectedId = { id, value: isSelected };
        const idExists = selectedIds.some((selectedId: SelectedIdType) => selectedId.id === id);

        if (idExists) {
            setSelectedIds(selectedIds.map((selectedId: SelectedIdType) =>
                selectedId.id === id ? { ...selectedId, value: isSelected } : selectedId
            ));
        } else {
            setSelectedIds((prevSelectedIds) => [...prevSelectedIds, newSelectedId]);
        }
    };

    const handleStepperNext = (nextStep: number) => {
        setActiveStep(nextStep);
    };

    const getModelNameById = useCallback((id: string) => {
        const modelOption = modelOptions.find(option => option.code === id);
        return modelOption ? modelOption.description : 'Model not found';
    }, [modelOptions]);

    const handleModelYearClick = useCallback(async (event: any) => {
        const { checked } = event;
        const year = event.columnId;
        console.log("handleModelYearClick --", event);
        //update the model year in the state
        setVehicleOperationModelYears((prevModelYears) => prevModelYears.map((modelYearItem) =>
            modelYearItem.year === year ? { ...modelYearItem, inQCM: checked } : modelYearItem
        ));
    }, []);

    const populateVehicleOperationDataTableValues = useCallback(() => {
        if (vehicleOperationModelYears.length > 0 && selectedOperationOptions.length > 0) {
            let columns: any = [];
            let data: any = [];

            // Add the first column for the operation name
            columns.push({
                Header: getModelNameById(vehicleDetails.modelId),
                disableSortBy: true,
                accessor: 'operation',
                Cell: ({ value }: any) => VehicleOperationAnchorCell(value,vehicleDetails.modelId,handleOperationClick )
        });

        // Initialize the row data with the operation name
            let rowData: any = {
                operation: selectedOperationOptions[0].label
            };

            // Add columns for each year and populate the row data with checkboxes
            vehicleOperationModelYears.forEach((modelYears: any) => {
                columns.push({
                    Header: <div> <a onClick={() => handleYearClick(vehicleDetails, modelYears)}>{modelYears.year}</a></div>,
                    disableSortBy: true,
                    accessor: modelYears.year,
                    Cell: (props: any) => VehicleOperationModelYearCell(props.value, props, handleModelYearClick)
                });

                rowData[modelYears.year] = modelYears.inQCM;
            });

            // Add the single row to the data array
            data.push(rowData);

            setColumnValues([...columns]);
            setDataValues([...data]);
        }
    }, [vehicleOperationModelYears, vehicleDetails.modelId, selectedOperationOptions, handleModelYearClick, getModelNameById]);

    useEffect(() => {
        getSectionComponent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        if (activeStep === 1) {
            setIsYearSelection(false);
        }
    }, [activeStep, nextStep]);

    useEffect(() => {
        populateVehicleOperationDataTableValues();
    }, [vehicleOperationModelYears, populateVehicleOperationDataTableValues])

    const handleModelAndBundleClick = async (event: any) => {
        getVehicleBundleAssignments(event.bundleId, event.originalRow.modelId, event.originalRow.model);
    };



    const getYearById = (id: string) => {
        const yearOption = yearsOptions.find(option => option.code?.toString() === id);
        console.log("total year option " + JSON.stringify(yearsOptions));
        if (!yearOption) {
            return 'Year not found';
        }
        return yearOption.description;
    };

    const getBundleCodeById = (id: string) => {
        const bundleOption = bundleOptions.find(option => option.value === id);
        if (!bundleOption) {
            return 'Bundle code not found';
        }

        const match = bundleOption.label.match(/\[([^[\]]+)]/);
        return match ? match[1] : 'Bundle code not found';
    };

    const handleVehicleTextDetails = (bundleId: string, modelDesc: string, yearId: string ) => {
            setVehicleTextDetails(  getYearById(yearId) + ' - ' + modelDesc + ' - ' + getBundleCodeById(bundleId));
    };

    const populateVehicleBundleDataTableValues = (vehicleBundleDetails: any) => {
        if (vehicleBundleDetails) {
            let columns: any = [];
            let data: any = [];
            let isModelAdded : boolean = false;
            columns.push({
                Header: vehicleBundleDetails.year,
                disableSortBy: true,
                accessor: 'model'
            })
            if (vehicleBundleDetails.bundles){
                vehicleBundleDetails.bundles.forEach((parentElement: any) => {
                    columns.push({
                        Header: parentElement.bundleCode,
                        disableSortBy: true,
                        accessor: 'bundleCode-' + parentElement.bundleId,
                        Cell: (props: any) => BundleCell(props.value, parentElement.bundleId, props, handleModelAndBundleClick)
                    })
                    if (!isModelAdded) {
                        parentElement.models.forEach((childElement: any) => {
                            data.push(createJson(parentElement, childElement, isModelAdded));
                        });
                    }else{
                        data.forEach((dataElement: any) => {
                            parentElement.models.forEach((childElement: any) => {
                                dataElement['bundleCode-'+parentElement.bundleId] = childElement.inQCM === 1? 'X' : '-';
                            });
                        });
                    }
                    isModelAdded=true;
                });
            }
            setColumnValues(columns);
            setDataValues(data);
        }
    }

    function createJson(parentElement: any, childElement: any, isModelAdded: boolean)
    {
        let jsonData : any = {};
        if(!isModelAdded){
            jsonData['model'] = childElement.modelDesc;
        }
        let columnName = 'bundleCode-'+parentElement.bundleId;
        jsonData[columnName] = childElement.inQCM === 1? 'X' : '-';
        jsonData['modelId'] = childElement.modelId;
        jsonData['bundleId'] = parentElement.bundleId;
        return jsonData;
    }

    useEffect(() => {
        getStepperSteps();
        setYearsOptions([]);
        setModelOptions([]);
        getVehicleYears();
        if(screen==='vehiclebundle'){
            getVehicleBundles();
        }else if(screen==='vehicleoperation'){
            getModels();
            getVehicleOperations();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screen]);

    const onChangeYear = async (e: any) => {
        setVehicleDetails({
            ...vehicleDetails,
            yearId: e.target.value,
        });
    };

    const onChangeModel = async (e: any) => {
        setVehicleDetails({
            ...vehicleDetails,
            modelId: e.target.value,
        });
    }

    const getVehicleBundles = async () => {
        BundleService.getAll()
            .then(response => {
                const vehicleBundlesResponseModel = response.data?.bundlesResponseModel;
                if (vehicleBundlesResponseModel) {
                    let vehicleBundleOptions : MultiSelectOption[] = [];
                    vehicleBundlesResponseModel.forEach((element: any) => {
                        let bundleData : MultiSelectOption = { label: element.bundleDescription + ' [' + element.bundleCode + ']', value:element.id};
                        vehicleBundleOptions.push(bundleData);
                    });
                    setBundleOptions(vehicleBundleOptions);
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    const getVehicleOperations = async () => {
        OperationService.getAll()
            .then(response => {
                console.log('getOperations response:', response);
                const vehicleOperationList = response.data?.operationList;
                if (vehicleOperationList) {
                    let vehicleOperationOptions : MultiSelectOption[] = [];
                    vehicleOperationList.forEach((element: any) => {
                        const operationLanguage: OperationLanguage | undefined = element.operationLanguageList.find((opLang: { countryLanguageId: CountryLanguageEnum; }) => opLang.countryLanguageId === CountryLanguageEnum.US_ENG);
                        let operationData : MultiSelectOption = { label: operationLanguage ? operationLanguage.description + '('+ element.operationCode + ')' : '',
                            value:element.operationId};
                        vehicleOperationOptions.push(operationData);
                    });
                    setOperationOptions(vehicleOperationOptions);
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    const getIntervals = async () => {
        IntervalService.getAll()
            .then(response => {
                console.log('getIntervals response:', response);
                const intervalResponses = response.data?.intervalResponses;
                if (intervalResponses) {
                    let intervalOptions : MultiSelectOption[] = [];
                    intervalResponses.forEach((element: any) => {
                        let operationData : MultiSelectOption = { label: element.intervalDesc,value:element.intervalId};
                        intervalOptions.push(operationData);
                    });
                    setIntervalOptions(intervalOptions);
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    const getDrivingConditions = async () => {
        DrivingConditionService.getAll()
            .then(response => {
                console.log('getDrivingConditions response:', response);
                const drivingConditionListResponses = response.data?.drivingConditionResponses;
                if (drivingConditionListResponses) {
                    let bundleOptions : MultiSelectOption[] = [];
                    drivingConditionListResponses.forEach((element: any) => {
                        const drivingConditionLanguage: DrivingConditionLanguage | undefined = element.drivingConditionsLanguageList.find((dcLang: { countryLanguageId: CountryLanguageEnum; }) => dcLang.countryLanguageId === CountryLanguageEnum.US_ENG);
                        let operationData : MultiSelectOption = { label: drivingConditionLanguage ? drivingConditionLanguage.description : '',
                            value:element.drivingConditionId};
                        bundleOptions.push(operationData);
                    });
                    setDrivingConditionOptions(bundleOptions);
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    const getVehicleYears = async () => {
        VehicleConfigService.getVehicleYears()
            .then(response => {
                const responseModel = response.data?.response;
                if (responseModel) {
                    let yearIdOptions : OptionType[] = [];
                    responseModel.forEach((element: any) => {
                        let yearIdData : OptionType = { code:element.id,
                            description: element.description
                        };
                        yearIdOptions.push(yearIdData);
                    });
                    setYearsOptions(yearIdOptions);
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    const getModels = async () => {
        RecommendationService.getAllModels()
            .then(response => {
                const responseModel = response.data?.models;
                if (responseModel) {
                    let modelOptions : OptionType[] = [];
                    const uniqueModels = new Set<string>();
                    responseModel.forEach((element: any) => {
                        if (!uniqueModels.has(String(element.modelId))) {
                            uniqueModels.add(String(element.modelId));
                            let modelData: OptionType = {
                                code: `${element.modelId}`,
                                description: element.makeDescription + '~' + element.description
                            };
                            modelOptions.push(modelData);
                        }
                    });
                    modelOptions.sort((a, b) => a.description.localeCompare(b.description));
                    setModelOptions(modelOptions);
                }
            })
            .catch(e => {
                console.log(e);
            });
    }

    const getAllVehicleBundleAssignments = async () => {
        const request = {
            "yearId":  Number(vehicleDetails.yearId),
            "bundleIds": selectedBundleOptions?.map((bundleOption: any) => bundleOption.value)
        };
        RecommendationService.getAllVehicleBundles(request)
            .then(response => {
                populateVehicleBundleDataTableValues(response.data);
                setNextStep(activeStep + 1);
            })
            .catch(e => {
                console.log(e);
                confirmErrorMessage(e.response.data.errorMessage);
            });
    };

    const getModelYearsByModelAndOperation = async () => {

         const request = {
             "modelId":  Number(vehicleDetails.modelId),
             "operationId": selectedOperationOptions[0].value
         };
         RecommendationService.getModelYearsByModelAndOperation(request.modelId, request.operationId)
             .then(response => {
                 // de-dupe the model years
                 setVehicleOperationModelYears(response.data?.modelYears);
                 setNextStep(activeStep + 1);
             })
             .catch(e => {
                 console.log(e);
                 confirmErrorMessage(e.response.data.errorMessage);
             });

    }

    const getVehicleOperationAssignments = async () => {
        let selectedYearIds = isYearSelection? selectedYear : getSelectedYearIds();
        let drivingConditionId = isYearSelection? selectedDrivingConditionOptions[0].value : null;
        let intervalId = isYearSelection? selectedIntervalOptions[0].value : null;
        RecommendationService.getVehicleOperations(selectedOperationOptions[0].value, Number(vehicleDetails.modelId), selectedYearIds, drivingConditionId,intervalId)
            .then(response => {
                console.log('getVehicleOperations response:', response);
                setSelectedIds([]);
                populateMatrixDataTableValues(response.data.vehicleOperationDrivingConditionIntervals);
                setNextStep(2);
            })
            .catch(e => {
                console.log(e);
                confirmErrorMessage("No Vehicle available for the selected Operation");
            });
    }

    const groupByIntervalId = (drivingConditionIntervals: any[]) => {
        const groupedData: { [key: string]: any[] } = {};

        drivingConditionIntervals.forEach((drivingConditionInterval: any) => {
            const intervalId = drivingConditionInterval.intervalId + '@' + drivingConditionInterval.intervalDescription;
            let intervalMatrix : any = { drivingConditionId: drivingConditionInterval.drivingConditionId,
                drivingConditionDescription:drivingConditionInterval.drivingConditionDescription,
                isQCM:drivingConditionInterval.inQCM};

            if (!groupedData[intervalId]) {
                groupedData[intervalId] = [];
            }
            groupedData[intervalId].push(intervalMatrix);
        });

        return groupedData;
    };

    const populateMatrixDataTableValues = (vehicleDetails: any) => {
        if (!vehicleDetails) return;

        const columns: any[] = [];
        const subColumns: any[] = [];
        const data: any[] = [];
        const selectedIds: SelectedIdType[] = [];

        vehicleDetails.forEach((vehicleMatrix: any) => {
            if (!vehicleMatrix.vehicles) return;

            let matrixId = vehicleMatrix.operationId;

            vehicleMatrix.vehicles.forEach((vehicle: any) => {
                const groupedData = groupByIntervalId(vehicle.drivingConditionIntervals);
                const subData: any[] = [];

                // Sort groupedData by intervalId and then by drivingConditionId
                const sortedGroupedData = Object.entries(groupedData).sort(([intervalIdA, intervalMatricesA], [intervalIdB, intervalMatricesB]) => {
                    if (intervalIdA < intervalIdB) return -1;
                    if (intervalIdA > intervalIdB) return 1;
                    return 0;
                });

                if(screen==='vehiclebundle'){
                    matrixId = vehicleMatrix.bundleId;
                }

                sortedGroupedData.forEach(([intervalId, intervalMatrices]: [string, any[]]) => {
                    // Sort intervalMatrices by drivingConditionId in ascending order
                    intervalMatrices.sort((a: any, b: any) => a.drivingConditionId - b.drivingConditionId);

                    addColumn(columns, intervalId, intervalMatrices.length);
                    addSubData(subColumns, subData, selectedIds, matrixId, vehicle.vehicleId, intervalId, intervalMatrices);
                });

                const vehicleName = constructVehicleName(vehicle);
                data.push({
                    id: vehicle.vehicleId,
                    name: vehicleName,
                    year: vehicle.year,
                    subData: subData
                });
            });
        });

        setMatrixColumnValues(columns);
        setMatrixSubColumnValues(subColumns);
        setMatrixDataValues(data);
        setMatrixSelectedValues(selectedIds.map((selectedId: SelectedIdType) => selectedId.id));
        setSelectedIds(selectedIds);
    };

    const addColumn = (columns: any[], intervalId: string, colSpan: number) => {
        if (!columns.some(column => column.id === intervalId)) {
            columns.push({
                id: intervalId,
                name: intervalId.split('@')[1],
                colSpan: colSpan
            });
        }
    };

    const addSubData = (subColumns: any[], subData: any[], selectedIds: SelectedIdType[], bundleId: string, vehicleId: string, intervalId: string, intervalMatrices: any[]) => {

        intervalMatrices.forEach((intervalMatrix: any) => {
            const subDataId = `${bundleId}@${vehicleId}@${intervalId.split('@')[0]}@${intervalMatrix.drivingConditionId}`;
            const subDatacolumnId = `${bundleId}@${intervalId.split('@')[0]}@${intervalMatrix.drivingConditionId}`;
            if (!subColumns.some(subColumn => subColumn.id === subDatacolumnId)) {
                subColumns.push({
                    id: subDatacolumnId,
                    name: intervalMatrix.drivingConditionDescription
                });
            }

               subData.push({
                    id: subDataId,
                    isQCM: intervalMatrix.isQCM
                });

                if (intervalMatrix.isQCM) {
                    selectedIds.push({ id: subDataId, value: true });
                }

        });
    };

    const constructVehicleName = (vehicle: any) => {
        const drivetrain = vehicle.drivetrain ?? '';
        const fuel = vehicle.fuel ?? '';
        const transmission = vehicle.transmission ?? '';
        const cylinders = vehicle.cylinders ?? '';
        const displacement = vehicle.displacement ?? '';

        return `${drivetrain}\n${fuel}\n${transmission}\n${cylinders}\n${displacement}\n`;
    };

    const getVehicleBundleAssignments = async (bundleId : any, modelId: any, modelDesc: any) => {
        RecommendationService.getVehicleBundles(bundleId, modelId, vehicleDetails.yearId)
            .then(response => {
                console.log('getVehicleBundles response:', response);
                setSelectedIds([]);
                populateMatrixDataTableValues(response.data.vehicleBundleDrivingConditionIntervals);
                setNextStep(2);
                handleVehicleTextDetails(bundleId, modelDesc, vehicleDetails.yearId );
            })
            .catch(e => {
                console.log(e);
                confirmErrorMessage("No Vehicle available for the selected bundle");
            });
    };


    function getSectionComponent() {
        handleStepperNext(nextStep);
    }

    const resetForm = () => {
        setActiveStep(0);
        setNextStep(0);
        setVehicleDetails({
            yearId: '',
            modelId: '',
            bundleId: '',
        });
        setSelectedBundleOptions([]);
        setSelectedOperationOptions([]);
        setUpdateOperations(true);
        setIsSaveButtonClicked(false);
    };

    const handlePreviousClick = () => {
        setNextStep(activeStep - 1);
    };

    const handleOperationClick = (vehicleDetails: any) => {
        console.log('handleOperationClick:', vehicleDetails);
        getVehicleOperationAssignments();
    };

    const handleYearClick = (vehicleDetails: any, yearDetails : any) => {
        console.log('handleYearClick:', vehicleDetails, yearDetails);
        setSelectedYear(yearDetails.yearId);
        getDrivingConditions();
        getIntervals();
        setIsYearSelection(true);
    };


    const handleNextClick = () => {
        console.log('handleNextClick:', activeStep);

        if (isVehicleBundleScreen()) {
            return handleVehicleBundleScreen();
        }

        if (isVehicleOperationScreen()) {
            return handleVehicleOperationScreen();
        }
    };

    const handleVehicleBundleScreen = () => {
        if (!vehicleDetails.yearId) {
            return confirmErrorMessage("Please select year to proceed further").then(r => r);
        }
        if (selectedBundleOptions.length === 0) {
            return confirmErrorMessage("Please select at least one bundle to proceed further").then(r => r);
        }
        if (activeStep === 0) {
            return getAllVehicleBundleAssignments();
        }
    };

    const handleVehicleOperationScreen = () => {
        if (!vehicleDetails.modelId) {
            return confirmErrorMessage("Please select model to proceed further").then(r => r);
        }
        if (selectedOperationOptions.length === 0) {
            return confirmErrorMessage("Please select an operation to proceed further").then(r => r);
        }
        if (activeStep === 0) {
            return getModelYearsByModelAndOperation();
        }
        if (activeStep === 1) {
            let selectedYearIds = isYearSelection? selectedYear : getSelectedYearIds();
            console.log("selectedYears ---- ", selectedYearIds);
            if (selectedYearIds.length < 1) {
                return confirmErrorMessage("Please select at least one year model").then(r => r);
            }
            if (isYearSelection && selectedDrivingConditionOptions.length === 0) {
                return confirmErrorMessage("Please select an Driving Condition to proceed further").then(r => r);
            }
            if (isYearSelection && selectedIntervalOptions.length === 0) {
                return confirmErrorMessage("Please select an Interval to proceed further").then(r => r);
            }
            return getVehicleOperationAssignments();
        }
    };

    const isVehicleBundleScreen = () => screen === 'vehiclebundle';
    const isVehicleOperationScreen = () => screen === 'vehicleoperation';
    const getSelectedYearIds = () => vehicleOperationModelYears
        .filter(modelYearItem => modelYearItem.inQCM)
        .map(modelYearItem => modelYearItem.yearId);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUpdateOperations(event.target.checked);
    };

    const createBulkBundle = async () => {

        console.log('createBulkBundle:', selectedIds);
        let selectedIntervals: any = [];
        selectedIds.forEach((selectedId: SelectedIdType) => {
            if (selectedId.id !== undefined) {
                console.log(`ID: ${selectedId.id}, Value: ${selectedId.value}`);
                let intervalData = selectedId.id.split('@');

                let intervalMatrix = { bundleId: Number(intervalData[0]),
                    vehicleId: Number(intervalData[1]),
                    drivingConditionId: Number(intervalData[3]),
                    intervalId: Number(intervalData[2]),

                    isDeleted: !selectedId.value};
                selectedIntervals.push(intervalMatrix);
            }
        });
        const request = {
            "vehicleBundleRequests":selectedIntervals,
            "updateOperation": updateOperations
        };

        RecommendationService.saveVehicleBundles(request)
            .then(response => {

                confirmSuccessMessage("Saved vehicle with Bundles");

                console.log('saveVehicleBundles response:', response);
                if(response.data.createdVehicleBundles){
                    setCreatedRecords(response.data.createdVehicleBundles.length);
                }
                if(response.data.deletedVehicleBundles){
                    setDeletedRecords(response.data.deletedVehicleBundles.length);
                }
                toggleModal();
                setIsSaveButtonClicked(true);
            })
            .catch(e => {
                confirmErrorMessage("Error in save Vehicle Bundles ");
                console.log(e);
            });
    };

    const createBulkOperation = async () => {

        console.log('createBulkOperation:', selectedIds);

        let selectedIntervals: any = [];

        selectedIds.forEach((selectedId: SelectedIdType) => {
            if (selectedId.id !== undefined) {
                console.log(`ID: ${selectedId.id}, Value: ${selectedId.value}`);
                let intervalData = selectedId.id.split('@');

                let intervalMatrix = {
                    operationId: Number(intervalData[0]),
                    vehicleId: Number(intervalData[1]),
                    drivingConditionId: Number(intervalData[3]),
                    intervalId: Number(intervalData[2]),
                    isDeleted: !selectedId.value
                };
                selectedIntervals.push(intervalMatrix);
            }
        });
        const request = {
            "vehicleOperationRequests":selectedIntervals
        };

        RecommendationService.saveVehicleOperations(request)
            .then(response => {

                confirmSuccessMessage("Saved vehicle with Operations");

                console.log('saveVehicleBundles response:', response);
                if(response.data.createdVehicleBundles){
                    setCreatedRecords(response.data.createdVehicleOperations.length);
                }
                if(response.data.deletedVehicleBundles){
                    setDeletedRecords(response.data.deletedVehicleOperations.length);
                }
                toggleModal();
                setIsSaveButtonClicked(true);
            })
            .catch(e => {
                confirmErrorMessage("Error in save Vehicle Bundles ");
                console.log(e);
            });
    };

    const confirmErrorMessage = async (value: any) => {
        childCompRef.current.failureMessage(value);
    };

    const confirmSuccessMessage = async (value: any) => {
        childCompRef.current.successMessage(value);
    }

    const onChangeOperation = async (selectedOption: any) => {
        console.log('onChangeOperation:', selectedOption);
        setSelectedOperationOptions([selectedOption]);
    };

    const onChangeDrivingCondition = async (selectedOption: any) => {
        console.log('onChangeDrivingCondition:', selectedOption);
        setSelectedDrivingConditionOptions([selectedOption]);
    };

    const onChangeInterval = async (selectedOption: any) => {
        console.log('onChangeInterval:', selectedOption);
        setSelectedIntervalOptions([selectedOption]);
    };

    const onChangeBundle = async (selectedOptions: any) => {
        console.log('onChangeBundle:', selectedOptions);
        setSelectedBundleOptions(selectedOptions);
    };

    const handleSubmit = (values: any) => {
        const { yearId } = values;

        if (!yearId) {
            console.error("Year ID is required");
            return;
        }
        console.log("Form submitted with values:", values);
    };

    return (
        <>
            <div className="config-component">
                {screen === 'vehiclebundle' && <h2 className="fomo-type--title">Manage Bundles Recommendations</h2>}
                {screen === 'vehicleoperation' && <h2 className="fomo-type--title">Manage Operations Recommendations</h2>}
            </div>
            <Formik
                validationSchema={schema}
                initialValues={{ yearId: vehicleDetails.yearId  }}
                onSubmit={handleSubmit}
            >
                {({values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, dirty}) => (
                    <div className="stepper fvp-form">
                        <Stepper steps={stepperSteps} activeStep={activeStep}/>
                        <div className="modal-top-div">
                            <form noValidate onSubmit={handleSubmit}>
                                <div>
                                    <div className="fvp-form__group">
                                        <div className="active-step">
                                            {screen === 'vehiclebundle' && activeStep === 0 &&
                                                <div className="fvp-form__subgroup">
                                                    <div>&nbsp;</div>
                                                    <div>
                                                        <FVPSelect
                                                            style={{width: "200px"}}
                                                            label="Year:"
                                                            name="yearsOptions"
                                                            data-testid="year"
                                                            onChange={onChangeYear}
                                                            value={vehicleDetails.yearId}
                                                            options={yearsOptions}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="ext1-11">

                                                    </div>
                                                </div>}
                                            {screen === 'vehicleoperation' && activeStep === 0 &&
                                                <div className="fvp-form__subgroup">
                                                    <div>&nbsp;</div>
                                                    <div>
                                                        <FVPSelect
                                                            style={{width: "200px"}}
                                                            label="Model:"
                                                            name="modelOptions"
                                                            data-testid="model"
                                                            onChange={onChangeModel}
                                                            value={vehicleDetails.modelId}
                                                            options={modelOptions}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="ext1-11">

                                                    </div>
                                                </div>}
                                            {(screen === 'vehiclebundle' && activeStep === 0 ) &&
                                                <div className="fvp-form__subgroup">
                                                    <div>&nbsp;</div>
                                                    <div className="ext1-11" data-testid={'vehiclebundle-test'}>
                                                        <b> Please select the bundles you want to recommend.</b>
                                                        <div data-testid="bundle-test">
                                                            <Select
                                                                isMulti
                                                                name="bundle"
                                                                options={bundleOptions}
                                                                value={selectedBundleOptions}
                                                                onChange={onChangeBundle}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                closeMenuOnSelect={false}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>}
                                            {(screen === 'vehicleoperation' && activeStep === 0 ) &&
                                                <div className="fvp-form__subgroup">
                                                    <div>&nbsp;</div>
                                                    <div className="ext1-11" data-testid={'vehicleoperation-test'}>
                                                        <b> Please select the operation you want to recommend.</b>
                                                        <div data-testid="operation-test">
                                                            <Select
                                                                isMulti={false}
                                                                name="operation"
                                                                data-testid="operation"
                                                                options={operationOptions}
                                                                value={selectedOperationOptions}
                                                                onChange={onChangeOperation}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                closeMenuOnSelect={true}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>}
                                        </div>
                                        {screen === 'vehiclebundle' && activeStep === 1 && <div>
                                            <div className="ext5">
                                            </div>
                                            <div className="ext2">
                                                * {} Vehicle Bundle Assignments were found for the vehicle listed
                                                below
                                            </div>
                                            {<CFAdminDataTable
                                                columns={columnValues}
                                                data={dataValues}
                                                textModal={''}
                                                showFilter={false}
                                                showAdd={false}
                                                showPagination={false}
                                                pageSize={1000}
                                            />}
                                        </div>}
                                        {screen === 'vehicleoperation' && !isYearSelection && activeStep === 1 && <div>
                                            <div className="ext5">
                                            </div>
                                            <div className="ext2">
                                                * {} Vehicle Operation Assignments were found for the model years listed
                                                below
                                            </div>
                                            {<CFAdminDataTable
                                                columns={columnValues}
                                                data={dataValues}
                                                textModal={''}
                                                showFilter={false}
                                                showAdd={false}
                                                showPagination={false}
                                                pageSize={1000}
                                            />}
                                        </div>}
                                        {screen === 'vehicleoperation' && isYearSelection && activeStep === 1 && <div>
                                            <div className="ext5">
                                            </div>
                                            <div className="ext2">
                                                * {} Vehicle Operation Assignments were found for the model years listed
                                                below
                                            </div>
                                            <div className="ext1-11" data-testid={'vehicledc-test'}>
                                                <b> Please select the Driving Condition you want to recommend.</b>
                                                <div data-testid="dc-test">
                                                    <Select
                                                        isMulti={false}
                                                        name="driving-condition"
                                                        data-testid="driving-condition"
                                                        options={drivingConditionOptions}
                                                        value={selectedDrivingConditionOptions}
                                                        onChange={onChangeDrivingCondition}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        closeMenuOnSelect={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="ext1-11" data-testid={'vehicleinterval-test'}>
                                                <b> Please select the Interval you want to recommend.</b>
                                                <div data-testid="interval-test">
                                                    <Select
                                                        isMulti={false}
                                                        name="interval"
                                                        data-testid="interval"
                                                        options={intervalOptions}
                                                        value={selectedIntervalOptions}
                                                        onChange={onChangeInterval}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        closeMenuOnSelect={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>}
                                        {activeStep === 2 && <div>
                                            <div className="ext5">
                                            </div>
                                            {screen === 'vehiclebundle' && <div className="ext2">
                                                * {matrixDataValues.length} Vehicle Bundle Assignments were
                                                found for the vehicle <b> {vehicleTextDetails} </b> listed below
                                            </div>}
                                            {screen === 'vehicleoperation' && <div className="ext2">
                                                * {matrixDataValues.length} Vehicle Operation Assignments were
                                                found for the vehicle <b> {vehicleTextDetails} </b> listed below
                                            </div>}
                                            <div>
                                                {matrixDataValues && matrixDataValues.length > 0 &&
                                                    <CFAdminVehicleBundleMatrix
                                                        matrixColumnValues={matrixColumnValues}
                                                        matrixSubColumnValues={matrixSubColumnValues}
                                                        handleSelectedValues={handleSelectedValues}
                                                        matrixDataValues={matrixDataValues}
                                                        matrixSelectedValues={matrixSelectedValues}/>
                                                }
                                            </div>
                                            {screen === 'vehiclebundle' && <div className="center-content">
                                                Apply Changes to Vehicle Bundle Operations &nbsp;
                                                <input type="checkbox" id="applyChangeToOperation"
                                                       data-testid="applyChangeToOperation"
                                                       name="applyChangeToOperation"
                                                       onChange={handleCheckboxChange}
                                                       defaultChecked={updateOperations}
                                                       className="operation-checkbox"/>
                                                <br/>Please uncheck to apply bundle recommendations only.
                                            </div>}
                                        </div>}
                                    </div>
                                </div>
                                <div className="modal-footer button-frame">
                                    <button data-testid="step-cancel" className="fvp-button--outline fvp-button"
                                            type="submit"
                                            onClick={resetForm}>
                                        <i className="fvp-left-chevron"/>Cancel
                                    </button>
                                    <div className="right-align">
                                        {(activeStep !== 0) && (
                                            <button data-testid="step-prev"
                                                    className="fvp-button button-right-align" type="button"
                                                    onClick={handlePreviousClick} disabled={isSaveButtonClicked}>
                                                <i className="fvp-left-chevron"/> Previous
                                            </button>
                                        )}
                                        { (activeStep === 0 || (activeStep === 1 && screen === 'vehicleoperation')) && (
                                            <button data-testid="step-next"
                                                    className="fvp-button button-right-align" type="button"
                                                    onClick={() => handleNextClick()}>
                                                Next <i className="fvp-right-chevron"/>
                                            </button>
                                        )}
                                        {activeStep === stepperSteps.length - 1 && (
                                            <div className="button-container">
                                                {(
                                                    <button data-testid="step-submit"
                                                            className="fvp-button button-right-align" type="button" disabled={isSaveButtonClicked}
                                                            onClick={() => screen === 'vehiclebundle' ? createBulkBundle() : createBulkOperation()}>
                                                        Save <i className="fvp-right-chevron"/>
                                                    </button>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                )}
            </Formik>
            <CFAdminNotification ref={childCompRef}/>
            <CFAdminModal
                isOpen={isOpen}
                toggle={toggleModal}
                isAdd={false}
                title={ 'Confirm Configurations'}
            >
                <>
                    <b> Vehicle Bundle Assignments were updated successfully</b>
                    <div>
                        * Inserted - {createdRecords} Vehicle Bundle Assignments
                    </div>
                    <div>
                        * Deleted - {deletedRecords} Vehicle Bundle Assignments
                    </div>

                </>
            </CFAdminModal>
        </>
    );
}

export default CFAdminVehicleBundle;